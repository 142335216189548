import React, {useState} from "react"
import Layout from '../components/Layout'
import { Row, Col, Form, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"

export default function Home() {

  const [validated, setValidated] = useState(false);

  
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };


  return (
    <Layout page="contact">
      <Helmet>
        <body className="contact-body" />
        <meta charSet="utf-8" />
        <meta name="description" content="Contact The Able Guys and take first step towards an awesome product team for your dream product" /> 
        <title>Contact The Able Guys</title>
      </Helmet>
     
      <div className="consistent-gap-small text-left">
                                      <div className="form-text">

                                          <h1 className="heading-careers purple">Hello to hi.</h1>
                                          <p className="description">One message to <strong className="purple">change the future of your idea or business.</strong></p>
                                          <small className="small-big">or call us at <a href="tel:+14158959105">+1 (415) 895-9105</a> US</small><br/>
                                          <small className="small-big">or call us at <a href="tel:+919106348688">+91 9106-348688</a> India</small>
                                          

                                      </div>

                                      <div className="form">

                                            <Form className="needs-validation" noValidate validated={validated} onSubmit={handleSubmit} netlify-honeypot="bot-field" data-netlify="true" name="contact" method="POST" action="/thanks">

                                              
                                              <input name="bot-field" type ="hidden" />
                                              <input type="hidden" name="form-name" value="contact" />
                                              

                                              <Form.Row>
                                                <Form.Group as={Col} md="6" controlId="name">
                                                  <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Your name"
                                                    name="name"
                                                    className="input-pad"
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                      You do have a name, right?
                                                  </Form.Control.Feedback>
                                                </Form.Group>


                                                <Form.Group as={Col} md="6" controlId="email">
                                                  <Form.Control
                                                    required
                                                    type="email"
                                                    placeholder="Your email"
                                                    name="email"
                                                    className="input-pad"
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address
                                                  </Form.Control.Feedback>
                                                </Form.Group>                                                
                                              </Form.Row>

                                              <Form.Row>
                                                <Form.Group as={Col} controlId="message">
                                                <Form.Control
                                                    required
                                                    placeholder="What can we do for you? Things like Idea, Timeline, New or Existing Product"
                                                    name="message"
                                                    className="input-pad"
                                                    as="textarea"
                                                    rows={9}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    Please enter something
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Form.Row>

                                              <Row>
                                                <Col md={8}></Col>
                                                <Col md={4}>
                                                  <div className="text-right">
                                                    <Button className="btn btn-outline-success btn-lg mt-4 btn-block text-left btn-contact" variant="" type="submit">
                                                      Send Message<svg className="service-arrow text-right" width="24" height="24" viewBox="0 0 32 32"><g fillRule="evenodd"><path transform="scale(-1 1) rotate(45 -11.999 -25.642)" d="M5.364 -0.236H7.364V15.764H5.364z"></path><path transform="rotate(-135 15.163 15.994)" d="M5.364 9.764H7.364V25.764H5.364z"></path><path d="M2 14H26V16H2z" transform="matrix(-1 0 0 1 29 1)"></path></g></svg>
                                                    </Button>
                                                  </div>                                                
                                                </Col>
                                              </Row>
                                              


                                            </Form>
                                      </div>
                                      
                          </div>
                          
    
    </Layout>
  )
}
